// Based on the original react-tagsinput styles

.react-tagsinput {
    display: inline-block;
    padding: 4px 6px;
    max-width: 100%;
    line-height: 22px;
}

.react-tagsinput-tag {
    cursor: pointer;
    margin: 5px 3px 5px 0;
    position: relative;
    padding: 3px 8px;
    border-radius: 12px;
    color: #ffffff;
    font-weight: 500;
    font-size: 0.75em;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1.5em;
    padding-left: 0.8em;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    font-family: Font awesome\5 Free;
    content: "\f00d";
    padding: 0px 2px;
    font-weight: 900;
}
.react-tagsinput-tag a {
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 0px;
    opacity: 0;
    background-color: transparent;
    color: #ffffff;
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 80px;
}

.react-tagsinput {
    .react-tagsinput-tag {
        -webkit-transition: all 300ms ease 0s;
        -moz-transition: all 300ms ease 0s;
        -o-transition: all 300ms ease 0s;
        -ms-transition: all 300ms ease 0s;
        transition: all 300ms ease 0s;

        &:hover {
            padding-right: 22px;

            a {
                opacity: 1;
                padding-right: 4px;
                background-color: transparent;
                color: #ffffff;
            }
        }

        // @include badges-color($gray-light);
        //
        // &.primary{
        //   @include badges-color($brand-primary);
        // }
        // &.info{
        //   @include badges-color($brand-info);
        // }
        // &.success{
        //   @include badges-color($brand-success);
        // }
        // &.warning{
        //   @include badges-color($brand-warning);
        // }
        // &.danger{
        //   @include badges-color($brand-danger);
        // }
        // .rose{
        //   @include badges-color($brand-rose);
        // }

        &.primary {
            background-color: $brand-primary;
        }
        &.info {
            background-color: $brand-info;
        }
        &.success {
            background-color: $brand-success;
        }
        &.warning {
            background-color: $brand-warning;
        }
        &.danger {
            background-color: $brand-danger;
        }
        &.rose {
            background-color: $brand-rose;
        }
        &.default {
            background-color: $gray-light;
        }
        background-color: $gray-light;
    }
}
